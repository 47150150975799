import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Use back arrow icon
import { useNavigate } from "react-router-dom";
import TreeNetwork from "./TreeNetwork"; // Import the TreeNetwork component
import iouLogo from "./iouLogo.png"; // Assuming iouIcon.png is in the assets folder
import "./styles.css";
import iouDiagramTransparent from "./iouDiagramTransparent.png"; // Import the new image
import businessLineOfCredit from "./businessLineOfCredit.png"; // Import the new image

const MechanicsPage = () => {
  const navigate = useNavigate();

  // Redirect to home
  const handleIconClick = () => {
    navigate("/"); // Redirect to homepage
  };

  const handleBackClick = () => {
    navigate("/overview"); // Redirect to the overview page
  };

  return (
    <Box position="relative" width="100%" height="100vh">
      {/* Background TreeNetwork */}
      <TreeNetwork
        initialNodes={50}
        maxNodes={50}
        addNodeInterval={2000}
        circleRadius={20}
        ballOpacity={0.05}
        lineOpacity={0.02}
        circleVisible={false}
      />

      {/* IOU Icon in top-left corner */}
      <IconButton
        onClick={handleIconClick}
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          zIndex: 2, // Ensure icon is above everything
        }}
      >
        <img
          src={iouLogo}
          alt="IOU Icon"
          style={{ width: "40px", height: "40px" }}
        />{" "}
        {/* Adjust size as needed */}
      </IconButton>

      {/* Scrollable Content */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          width: "100%", // Full width
          height: "calc(100vh)", // Dynamic height
          overflowY: "auto", // Allow scrolling vertically
        }}
      >
        <div class="contentWrapper">
          <Typography variant="h2" align="center" gutterBottom color="white">
            IOU Finance Mechanics
          </Typography>
          <Typography variant="body1" paragraph color="white">
            It's important to note that IOU Finance, especially at the
            beginning, will be highly permissioned in terms of who is allowed to
            borrow on the platform. Only high-quality, creditworthy borrowers
            (primarily established protocols or very high profile individuals)
            will be allowed to access the platform and credit. The lending side
            will be open and non-permissioned, similar to Aave, Fraxlend, or
            other lending protocols.
          </Typography>
          <Typography variant="body1" paragraph color="white">
            IOU Finance operates through 2 separate but interconnected
            facilities, a business credit facility that provides borrowers with
            an instant line of credit and the IOU issuance platform which allows
            greater customization of debt terms and direct debtor/creditor
            relationship between the IOU issuer (the borrower) and the IOU buyer
            (the lender).
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            1. Business Line of Credit
          </Typography>

          <Typography variant="body1" paragraph color="white">
            The business line of credit is an open-ended lending facility where
            lenders can deposit funds to provide credit to a variety of vetted
            borrowers. This pool offers immediate access to liquidity for
            borrowers without requiring individual issuance or sales of IOUs.
            For lenders the pool provides the benefit of diversification, as
            funds are spread across multiple borrowers, as well as allowing them
            to avoid the due diligence work of researching and purchasing an
            individual IOU where credit risk is dependent on 1 specific
            borrower. The protocol sets credit limits and interest rates for
            each borrower based on their creditworthiness. Repayments will be
            required on a rolling basis, similar to a credit card model.
          </Typography>
          <Box
            component="img"
            src={businessLineOfCredit}
            alt="IOU Diagram"
            sx={{
              width: "50%", // Full width
              marginTop: "20px", // Add some margin if needed
              display: "block", // Make the image a block element
              margin: "20px auto", // Center align the image
            }}
          />
          <Typography variant="h4" gutterBottom color="white">
            2. IOU Issuance Platform
          </Typography>
          <Typography variant="body1" paragraph color="white">
            The IOU issuance platform offers a more tailored borrowing solution.
            This facility allows individual borrowers to issue IOUs with more
            customizable terms as well as potentially higher limits than the
            business line of credit allows. Issued IOUs are purchased directly
            by individual lenders and after the primary sale will be tradable on
            secondary markets. While debt limits are set by the protocol to
            mitigate systemic risk, the terms of the debt—such as interest rates
            and maturity—can be customized to a greater degree by the lender or
            set dynamically through mechanisms like dutch auctions. Higher
            credit limits will also be possible through this facility due to the
            more direct relationship between borrower and lender.
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            Risk Management
          </Typography>
          <Typography variant="body1" paragraph color="white">
            Given the nature of IOU Finance, managing risk is critical. To that
            end, each borrower is assigned two thresholds:
          </Typography>
          <Typography variant="body1" paragraph color="white" component="div">
            <ul>
              <li>
                <strong>Absolute Threshold:</strong> This sets a hard limit on
                how much debt a borrower can issue at any given time. Once this
                limit is reached, no new debt can be issued until existing debt
                is repaid. This limit exists both to protect lenders as well as
                the protocol itself. Even though IOU Finance does not act as a
                counterparty, defaults are still highly non-desirable
                considering the entire foundation of the platform rests on trust
                and a sense of reasonable safety for lenders which defaults
                undermine.
              </li>
              <li>
                <strong>Refinance Threshold:</strong> Once a borrower’s
                outstanding debt surpasses this level, any new debt must also
                refinance existing obligations. This ensures that earlier
                lenders are repaid before additional debt can be issued, thus
                protecting earlier lenders who lent at lower risk levels.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" paragraph color="white">
            Additionally, earlier issued debt is always more senior to later
            issued debt, even if below the Refinance Threshold. The goal here is
            to maintain lender confidence and reduce the risk of borrower
            overextension, or at least to have further extension of debt be
            priced appropriately. When increasing debt through IOUs, APRs on
            borrows from the business line of credit for the specific borrower
            may also increase. APRs on earlier IOUs will not necessarily
            increase unless the IOU was specifically issued with a floating APR.
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            Borrower-Issued Stablecoin
          </Typography>
          <Typography variant="body1" paragraph paddingTop={4} color="white">
            One unique feature of the IOU issuance platform is that while IOUs
            will be available to purchase with Frax for convenience, all debt is
            denominated and repayed in a custom stablecoin issued by the
            borrowing protocol instead of Frax itself. The mechanics of both the
            creation of this new stablecoin (eg BORROWER USD) and conversion
            both to and from Frax will be seamless and completely abstracted
            from both borrower and lender, yet in a technical sense what is
            being borrowed and repayed is BORROWER USD and not Frax. Because the
            new stablecoin can be minted freely repayments for the IOU will be
            pre-locked in the IOU contract and will be immediately and
            automatically redeemable by the lender upon maturity. If the
            borrower already has their own stablecoin (eg AAVE's GHO) this may
            be used in place of a custom stablecoin as long as the requirement
            of advance locking of repayment at the time of IOU issuance is met.
          </Typography>
          <Typography variant="body1" paragraph color="white">
            This part of the process may seem strange at first glance but it
            accomplishes 3 goals:
          </Typography>
          <Typography variant="body1" paragraph color="white" component="div">
            <ol>
              <li>
                <strong>Makes defaults impossible in a technical sense.</strong>{" "}
                Because all debt is denominated in the custom stablecoin, and
                payments for the lender are locked up in advance, in one sense
                the lender is guaranteed to receive their repayment and the
                borrower cannot default. Of course in the non-technical sense of
                "did the lender get their value back" nothing will change as
                this will still rely on the borrower actually repaying their
                debt and replenishing the BORROWER USD &lt;-&gt; Frax swap
                facility.
              </li>
              <li>
                <strong>
                  Directly ties the responsibility of the debt to the borrower.
                </strong>{" "}
                In the event the borrower does default their newly issued
                stablecoin will lose peg and lenders will not be able to redeem
                BORROWER USD 1 to 1 with Frax. This will be explicitly the fault
                of the borrower and connects their failure to maintain their
                obligations in the direct way as well as permanently linking a
                failed stablecoin to the borrower itself.
              </li>
              <li>
                <strong>
                  Allows the borrower greater flexibility in repayment including
                  the ability to denominate debt in their own stablecoin.
                </strong>{" "}
                Although IOU Finance will provide a seamless experience for both
                borrowers and lenders in terms of the IOU USD &lt;-&gt; Frax
                conversion process the borrower can defend the peg of their
                stablecoin in whatever way they see fit including by creating
                liquidity pools on 3rd party platforms outside of the protocol.
              </li>
            </ol>
          </Typography>
          <Box
            component="img"
            src={iouDiagramTransparent}
            alt="IOU Diagram"
            sx={{
              width: "100%", // Full width
              marginTop: "20px", // Add some margin if needed
            }}
          />

          <Typography variant="h4" gutterBottom color="white">
            Long-Term Vision
          </Typography>
          <Typography variant="body1" paragraph color="white">
            While IOU Finance begins as a highly permissioned platform, the
            long-term vision is to evolve into a more decentralized model. Over
            time, the protocol aims to shift toward a "web of trust" framework,
            where borrowing and lending relationships are established
            organically through networks of reputation, rather than relying
            solely on centralized vetting. Longer term greater focus will also
            be put on tail end risks including broad market shocks or declines
            where many borrowers maybe at risk of default simultaneously. We
            hope to mitigate this risk through a combination of insurance pools,
            3rd party institutional insurance providers as well as unique
            refinancing options in exceptional circumstances.
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            Conclusion
          </Typography>
          <Typography variant="body1" paragraph color="white">
            IOU Finance is challenging the status quo in DeFi lending by
            eliminating the requirement for collateral and relying instead on
            trust, reputation, and long-term relationships. By offering both a
            business line of credit for instant, low-friction credit access and
            a customizable IOU issuance platform, IOU Finance caters to lenders
            of various levels of sophistication as well as offering borrowers
            both fast access to capital and more customizable debt options to
            fit their needs. Through careful risk management, a permissioned
            borrower framework, and the eventual move toward decentralization,
            IOU Finance aims to unlock a new category of credit-based lending in
            DeFi.
          </Typography>
          {/* Button to redirect to overview page, left justified */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start", // Left justify the button
              marginTop: "40px",
            }}
          >
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />} // Add back arrow icon to the left
              onClick={handleBackClick}
              sx={{
                color: "white",
                borderColor: "white",
                textTransform: "none", // Disable uppercase transformation
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderColor: "white",
                },
              }}
            >
              Protocol Overview
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default MechanicsPage;
