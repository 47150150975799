import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import LandingPage from './LandingPage';
import OverviewPage from './OverviewPage';
import MechanicsPage from './MechanicsPage';
import { Box } from '@mui/material';
import styled from 'styled-components';

const Header = styled(Box)`
  position: fixed;
  top: 0;
  right: 20px;
  z-index: 10;
  padding: 20px;
  color: white;
  display: flex;
  gap: 20px;
`;

const NotFoundPage = () => (
  <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" color="white">
    <h1>Page Not Found</h1>
  </Box>
);

const HeaderWithConditionalHome = () => {
  const location = useLocation();

  return (
    <Header>
      {/* Conditionally render "Home" button based on the current path */}
      {location.pathname !== '/' && (
        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>
          Home
        </Link>
      )}
      <Link to="/overview" style={{ textDecoration: 'none', color: 'white' }}>
        Overview
      </Link>
      <Link to="/mechanics" style={{ textDecoration: 'none', color: 'white' }}>
        Mechanics
      </Link>
    </Header>
  );
};

const App = () => {
  return (
    <Router>
      <Box position="relative">
        {/* Header with conditional Home button */}
        <HeaderWithConditionalHome />

        {/* Define Routes */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/overview" element={<OverviewPage />} />
          <Route path="/mechanics" element={<MechanicsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Box>
    </Router>
  );
};

export default App;
