import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import TreeNetwork from "./TreeNetwork";
import iouLogo from "./iouLogo.png"; // Assuming you have iouIcon.png in your assets folder
import "./styles.css"

const OverviewPage = () => {
  const navigate = useNavigate();

  // Redirect to home
  const handleIconClick = () => {
    navigate("/"); // Redirect to homepage
  };

  const handleNextClick = () => {
    navigate("/mechanics"); // Redirect to the mechanics page
  };

  return (
    <Box position="relative" width="100%" height="100vh">
      {/* Background TreeNetwork */}
      <TreeNetwork
        initialNodes={50}
        maxNodes={50}
        addNodeInterval={2000}
        circleRadius={20}
        ballOpacity={0.05}
        lineOpacity={0.02}
        circleVisible={false}
      />

      {/* IOU Icon in top-left corner */}
      <IconButton
        onClick={handleIconClick}
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          zIndex: 2, // Ensure icon is above everything
        }}
      >
        <img
          src={iouLogo}
          alt="IOU Icon"
          style={{ width: "40px", height: "40px" }}
        />{" "}
        {/* Adjust size as needed */}
      </IconButton>

      {/* Scrollable Content */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
          width: "100%", // Full width
          height: "calc(100)", // Dynamic height
          overflowY: "auto", // Allow scrolling vertically
        }}
      >
        <div class="contentWrapper">
          <Typography variant="h2" align="center" gutterBottom color="white">
            IOU Finance Overview
          </Typography>
          <Typography variant="body1" paragraph color="white">
            TL/DR: We're doing non-collateralized, credit based lending in DeFi.
          </Typography>
          <Typography variant="body1" paragraph color="white">
            DeFi lending up to this point has been dominated by 1 model:
            over-collateralized loans. This model has been very successful and
            many protocols from Aave to Fraxlend to Silo have been built on top of
            this premise. The core argument is as long as this equation holds:
            (Value of Funds borrowed &lt; Value of Collateral Deposited) trustless
            borrowing is possible. That a borrower will always lose more than
            they'll gain when defaulting provides cryptoeconomic security.
          </Typography>
          <Typography variant="body1" paragraph color="white">
            However we can abstract this equation a bit to: (Value of Funds
            borrowed &lt; Penalty for Not Repaying Funds). This still holds
            however it's not obvious what easy to implement, scalable penalty
            would be better in a trustless environment than seizing collateral.
            What if we weren't operating in a trustless environment though? What
            if instead of relying on short term, "hard" penalties and enforcement
            to make the equation work we were instead leveraging the invisible but still
            real trust and accrued goodwill that exists in networks of long term
            reputable actors combined with those actors own rational self interest
            in wanting to have access to capital not just now but also for the
            long term? If this could be achieved it would possibly unlock an
            entirely new category in DeFi- non-collateralized, credit based
            lending.
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            (Inf,Inf)
          </Typography>
          <Typography variant="body1" paragraph color="white">
            Probably the most famous problem in game theory is the Prisoner's
            Dilemma. We'll assume familiarity with the problem and that
            game-theoretically player's choices should collapse into the non-ideal
            defect/defect scenario. However slightly less well known is what
            happens in an iterated prisoner's dilemma- that is what if the players
            play the game multiple times, say n times? Well this actually also
            should collapse into a string of defect/defects due to both players
            knowing there are no games after n and thus having no incentive not to
            defect/defect in game n. Then since both players know game n is
            defect/defect there's no incentive not to defect in game n-1 and this
            process continues iteratively until we have an unbroken line of
            defect/defect for all games.
          </Typography>

          <Typography variant="body1" paragraph color="white">
            What if we're not playing a finite number of games though but instead
            an infinite (or actually just unknown) number of games? This has been
            shown, both theoretically (
            <a
              href="https://www.degruyter.com/document/doi/10.1515/9781400882168-018/html"
              style={{ color: "white" }}
            >
              source
            </a>
            ): and in practice (
            <a
              href="https://en.wikipedia.org/wiki/Prisoner's_dilemma#CITEREFAxelrod2006"
              style={{ color: "white" }}
            >
              source
            </a>
            ) that the game-theoretic outcome does not collapse into an endless
            string of defect/defect but instead that sustained cooperation is
            actually possible.
          </Typography>

          <Typography variant="body1" paragraph color="white">
            This is a toy model but we think does have an analogy to real world
            long term cooperation and partnerships. This hidden value in (inf,inf)
            is what we're going after and think has been highly underexplored in
            DeFi up to this point.
          </Typography>
          <Typography variant="h4" gutterBottom color="white">
            Why This Model Can Work
          </Typography>

          <Typography variant="body1" paragraph color="white">
            Besides game theory though, what evidence is there this model can
            actually work? The idea of non-collateralized lending in DeFi is at first
            glance highly counterintuitive. The most obvious question is of course
            "won't borrowers just run off with the money?" This is a reasonable
            question, however there are many common scenarios in crypto that
            actually have very high amounts of trust that nearly always avoid
            analogous bad behavior:
          </Typography>

          <Typography variant="body1" paragraph color="white" component="div">
            <ol>
              <li>
                
               <strong>Point Programs.</strong> Although controversial, points programs have
                become hugely popular ever since Blur introduced the concept back
                in 2022. Participants trust that the protocol will allocate
                rewards fairly even though in almost all cases no explicit
                enforcement prevents the protocol from malicious misallocation.
                Despite this, although some programs have been received better
                than others, there have been no examples of reputable protocols
                effectively "rugging" users and leaving them with an obviously
                unfair allocation for the protocol's own benefit.
              </li>
              <li>
                <strong>Protocol Governance.</strong> While DeFi protocols often emphasize
                decentralized governance there are typically no hard mechanisms to
                ensure governance votes are enacted. The system works because of
                the trust that protocol administrators will carry out the
                decisions made by token holders, even without direct enforcement.
              </li>
              <li>
                <strong>Management of protocol funds and contracts.</strong> In many cases,
                protocol funds and upgradable contracts are controlled by a small
                group of insiders, including many anons. Despite this, insider
                abuse or theft remains rare, especially among reputable teams. The
                implicit trust in protocol managers is often sufficient to prevent
                malfeasance, suggesting that reputation and long-term
                relationships can govern behavior effectively.
              </li>
            </ol>
          </Typography>
          {/* Button to redirect to mechanics page, right justified */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // Right justify the button
              marginTop: "40px",
            }}
          >
            <Button
              variant="outlined"
              endIcon={<ArrowForwardIcon />} // Add arrow icon to the right
              onClick={handleNextClick}
              sx={{
                color: "white",
                borderColor: "white",
                textTransform: "none", // Disable uppercase transformation
                padding: "10px 20px",
                fontSize: "16px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  borderColor: "white",
                },
              }}
            >
              Protocol Mechanics
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default OverviewPage;
