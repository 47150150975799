import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import styled from 'styled-components';
import Typewriter from 'typewriter-effect';
import TreeNetwork from './TreeNetwork'; // Import the TreeNetwork component
import iouLogo from "./iouLogo.png"; // Import the iouLogo from the correct path
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection



const OverlayContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Allow mouse events to pass through */
`;

const TextContainer = styled(Box)`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2); /* Semi-transparent background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Allow mouse events to pass through */
`;

const TextBox = styled(Box)`
  pointer-events: auto; /* Allow mouse events */
`;

const BottomText = styled(Typography)`
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.6); /* Less bright color */
`;

const LandingPage = () => {
  const [showBlinkingCursor, setShowBlinkingCursor] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let timeout;
    if (!showBlinkingCursor) {
      timeout = setTimeout(() => setShowBlinkingCursor(true), 1000);
    }
    return () => clearTimeout(timeout);
  }, [showBlinkingCursor]);

  const handleIconClick = () => {
    navigate("/"); // Redirect to homepage
  };

  return (
    <Box position="relative" width="100%" height="100vh">
      <TreeNetwork />
      <IconButton
        onClick={handleIconClick}
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
          zIndex: 2, // Ensure the logo is on top of everything
        }}
      >
        <img src={iouLogo} alt="IOU Logo" style={{ width: '40px', height: '40px' }} /> {/* Adjust size as needed */}
      </IconButton>
      <OverlayContainer>
        <TextContainer>
          <TextBox>
            <Typography variant="h1" component="h1" color="white" display="inline">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString('IOU Finance')
                    .callFunction((state) => {
                      state.elements.cursor.style.animation = 'none';
                      state.elements.cursor.style.display = 'none';
                      setShowBlinkingCursor(true);
                    })
                    .start();
                }}
                options={{
                  delay: 75, // Adjust this to control typing speed
                }}
              />
            </Typography>
            {showBlinkingCursor && (
              <Typography variant="h5" component="h2" color="white" display="inline">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .pauseFor(1000)
                      .typeString('Collateralized')
                      .pauseFor(1000)
                      .deleteAll()
                      .typeString('<strong>Non-Collateralized</strong> Loans')
                      .pauseFor(1000)
                      .deleteAll(50)
                      .typeString('Bringing Trust to a Trust-less Network')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Redefining DeFi Loans, One IOU at a Time')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Trust in Tomorrow, Today')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Non-Collateralized Loans for a New Era')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Changing the Way You Borrow')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Redefining Trust in Decentralized Finance')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Trust the Trust-less with iou.finance')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Innovative Trust in a Decentralized World')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Building Trust on the Blockchain')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Trust Redefined for DeFi')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('Creating Trust in Decentralized Lending')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .typeString('From Trust-less to Trusted with iou.finance')
                      .pauseFor(2000)
                      .deleteAll(50)
                      .start(); // Restart the sequence
                  }}
                  options={{
                    loop: true,
                    delay: 75,
                    deleteSpeed: 50,
                  }}
                />
              </Typography>
            )}
          </TextBox>
        </TextContainer>
      </OverlayContainer>
    </Box>
  );
};

export default LandingPage;
