import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Engine, Render, Runner, Bodies, Composite, Mouse, MouseConstraint, Events, Vector, Body } from 'matter-js';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #1a1a1a;
`;

const TreeNetwork = ({
  initialNodes = 50,
  maxNodes = 50,
  addNodeInterval = 2000,
  circleRadius = 20,
  ballOpacity = 0.2,
  lineOpacity = 0.2,
  circleVisible = false,
}) => {
  const containerRef = useRef(null);

  // Constants
  const CANVAS_WIDTH = window.innerWidth;
  const CANVAS_HEIGHT = window.innerHeight;
  const CIRCLE_COLOR = `rgba(255, 255, 255, ${ballOpacity})`;

  useEffect(() => {
    // Create an engine with reduced gravity
    const engine = Engine.create({
      gravity: {
        x: 0,
        y: 0
      }
    });

    // Create a renderer
    const render = Render.create({
      element: containerRef.current,
      engine: engine,
      options: {
        width: CANVAS_WIDTH,
        height: CANVAS_HEIGHT,
        background: '#1a1a1a',
        wireframes: false
      }
    });

    // Add initial nodes
    const nodes = [];
    const connections = [];

    for (let i = 0; i < Math.min(initialNodes, maxNodes); i++) {
      const node = Bodies.circle(Math.random() * CANVAS_WIDTH, Math.random() * CANVAS_HEIGHT, circleRadius, {
        render: {
          fillStyle: CIRCLE_COLOR,
          visible: circleVisible
        }
      });
      nodes.push(node);
    }

    // Add all of the bodies to the world
    Composite.add(engine.world, nodes);

    // Generate random connections within sub-trees
    const generateConnections = (nodes, minConnections, maxConnections) => {
      nodes.forEach(node => {
        const numConnections = Math.floor(Math.random() * (maxConnections - minConnections + 1)) + minConnections;
        for (let i = 0; i < numConnections; i++) {
          const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
          if (randomNode !== node) {
            connections.push({ nodeA: node, nodeB: randomNode });
          }
        }
      });
    };

    generateConnections(nodes, 1, 3);

    // Add boundaries slightly outside the canvas area
    const boundaryOffset = 50;
    const boundaries = [
      Bodies.rectangle(CANVAS_WIDTH / 2, -boundaryOffset, CANVAS_WIDTH, 20, { isStatic: true }), // Top
      Bodies.rectangle(CANVAS_WIDTH / 2, CANVAS_HEIGHT + boundaryOffset, CANVAS_WIDTH, 20, { isStatic: true }), // Bottom
      Bodies.rectangle(-boundaryOffset, CANVAS_HEIGHT / 2, 20, CANVAS_HEIGHT, { isStatic: true }), // Left
      Bodies.rectangle(CANVAS_WIDTH + boundaryOffset, CANVAS_HEIGHT / 2, 20, CANVAS_HEIGHT, { isStatic: true }) // Right
    ];
    Composite.add(engine.world, boundaries);

    // Add mouse control
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        render: {
          visible: false
        }
      }
    });
    Composite.add(engine.world, mouseConstraint);

    // Keep the mouse in sync with rendering
    render.mouse = mouse;

    // Add new nodes periodically
    const addNode = () => {
      if (nodes.length >= maxNodes) return;

      const node = Bodies.circle(Math.random() * CANVAS_WIDTH, Math.random() * CANVAS_HEIGHT, circleRadius, {
        render: {
          fillStyle: CIRCLE_COLOR,
          visible: circleVisible
        }
      });
      nodes.push(node);
      Composite.add(engine.world, node);

      // Connect new node to existing nodes
      if (nodes.length > 1) {
        const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
        connections.push({ nodeA: node, nodeB: randomNode });
      }
    };

    const interval = setInterval(addNode, addNodeInterval);

    // Draw connections
    const drawConnections = () => {
      const context = render.context;
      connections.forEach(connection => {
        context.beginPath();
        context.moveTo(connection.nodeA.position.x, connection.nodeA.position.y);
        context.lineTo(connection.nodeB.position.x, connection.nodeB.position.y);
        context.strokeStyle = `rgba(255, 255, 255, ${lineOpacity})`;
        context.lineWidth = 2;
        context.stroke();
      });
    };

    // Add render event to draw connections
    Events.on(render, 'afterRender', drawConnections);

    // Attract nodes to mouse and repel if too close
    const attractAndRepelNodes = () => {
      const mousePosition = mouse.position;

      nodes.forEach(node => {
        const forceDirection = Vector.sub(mousePosition, node.position);
        const distance = Vector.magnitude(forceDirection);
        const normalizedForce = Vector.normalise(forceDirection);
        let strength = Math.min(1 / (distance * distance), 0.01);

        // Repel nodes if too close to the mouse
        if (distance < 50) {
          strength = -0.05;
        }

        const force = Vector.mult(normalizedForce, strength);
        Body.applyForce(node, node.position, force);
      });
    };

    // Repel nodes from each other
    const repelNodesFromEachOther = () => {
      for (let i = 0; i < nodes.length; i++) {
        for (let j = i + 1; j < nodes.length; j++) {
          const nodeA = nodes[i];
          const nodeB = nodes[j];
          const forceDirection = Vector.sub(nodeA.position, nodeB.position);
          const distance = Vector.magnitude(forceDirection);
          if (distance < 50) {
            const normalizedForce = Vector.normalise(forceDirection);
            const strength = 0.01;
            const force = Vector.mult(normalizedForce, strength);
            Body.applyForce(nodeA, nodeA.position, force);
            Body.applyForce(nodeB, nodeB.position, Vector.neg(force));
          }
        }
      }
    };

    // Add render event to attract nodes to mouse and repel if too close
    Events.on(engine, 'beforeUpdate', attractAndRepelNodes);
    Events.on(engine, 'beforeUpdate', repelNodesFromEachOther);

    // Handle window resize
    const handleResize = () => {
      render.canvas.width = window.innerWidth;
      render.canvas.height = window.innerHeight;
      Render.lookAt(render, {
        min: { x: 0, y: 0 },
        max: { x: CANVAS_WIDTH, y: CANVAS_HEIGHT }
      });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    // Create and run the runner
    const runner = Runner.create();
    Runner.run(runner, engine);

    // Run the renderer
    Render.run(render);

    // Clean up
    return () => {
      clearInterval(interval);
      Render.stop(render);
      Runner.stop(runner);
      Engine.clear(engine);
      Composite.clear(engine.world, false);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
      window.removeEventListener('resize', handleResize);
    };
  }, [CANVAS_HEIGHT, CANVAS_WIDTH, circleVisible, CIRCLE_COLOR]);

  return <Container ref={containerRef} />;
};

export default TreeNetwork;
